import { Outlet } from "react-router-dom";
import GenericPage from "./GenericPage";
import { getLuminance } from "../../utils";
import BIcon from "../BIcon";
import { useRef, useState } from "react";

export default function AnnouncementPreview({ announcementObj, children }) {

  const [screen, setScreen] = useState("desktop")

  const activeClass = "text-white bg-primary hover:bg-primary-light"
  const inactiveClass = "border text-primary border-primary hover:border-primary-light"

  const screenSwitchDiv = (
    <div className="flex flex-row gap-2">
      <button onClick={() => setScreen("mobile")}
        className={`rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm ${screen == "mobile" ? activeClass : inactiveClass}`}>
        <BIcon name={screen == "mobile" ? "phone-fill" : "phone"} className={"me-1"} />Mobile
      </button>
      <button onClick={() => setScreen("desktop")}
        className={`rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm ${screen == "desktop" ? activeClass : inactiveClass}`}>
        <BIcon name={screen == "desktop" ? "laptop-fill" : "laptop"} className={"me-2"} />Desktop
      </button>
    </div>
  )

  const announcementDivRef = useRef(null)

  const extractStylesToJson = (element) => {
    // Helper function to apply computed styles and clone an element's styles into a JSON object recursively
    const cloneStylesToJson = (el, parentComputedStyles = {}) => {
      // Get computed styles for the current element
      const computedStyles = window.getComputedStyle(el);

      // Initialize a styles object
      let styleObject = {};
      for (let property of computedStyles) {
        if (computedStyles.getPropertyValue(property) !== parentComputedStyles[property]) {
          styleObject[property] = computedStyles.getPropertyValue(property);
        }
      }

      // Recursively collect styles of child elements
      let childrenStyles = [];
      Array.from(el.children).forEach((child) => {
        childrenStyles.push(cloneStylesToJson(child, computedStyles));
      });

      // Return an object with element's tag name, styles, and children
      return {
        tagName: el.tagName.toLowerCase(),
        styles: styleObject,
        children: childrenStyles
      };
    };

    // Use the helper function to collect the entire element tree with styles
    const styledJson = cloneStylesToJson(element);

    // Return the JSON representation of the styled element tree
    return styledJson;
  };

  const doer = () => {
    const element = announcementDivRef.current
    const styles = extractStylesToJson(element)
    // console.log(styles)
    // document.body.innerHTML = html

  }

  return (
    <div className="flex flex-row h-full">
      <div className="overflow-y-auto pe-12 pb-8">
        {children}
      </div>
      <div className="border-l ps-12 h-full grow">
        <div className="flex flex-row-reverse mb-4">
          {screenSwitchDiv}
        </div>
        <div className="flex justify-center items-center relative h-full max-h-[70vh]">
          <div>
            <div
              class="absolute mt-12 -z-10 inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"
            ></div>
            {announcementObj && (
              <div onClick={doer} ref={announcementDivRef} className="p-16 shadow-lg rounded-2xl w-[600px] --max-w-[360px]" style={{
                backgroundColor: announcementObj.backgroundColor,
                color: announcementObj.textColor
              }}>
                <h2 className="text-3xl font-bold text-center mb-6">
                  {announcementObj.title}
                </h2>
                <p className="text-center opacity-85 font-semibold mb-10">
                  {announcementObj.description}
                </p>
                {/* <form className="flex flex-row">
                <input type="email"/>
                <button type="submit">{announcementObj.callToActionTitle}</button>
              </form> */}
                <div className="flex justify-center gap-4">
                  {announcementObj.collectEmail && <input placeholder="email@example.com" className="text-black rounded-md" type="email" name="callToActionEmail" />}
                  <button className="py-3 px-6 rounded-md" style={{
                    backgroundColor: announcementObj.accentColor,
                    color: getLuminance(announcementObj.accentColor) > 0.5 ? 'black' : 'white'
                  }}>
                    {announcementObj.callToActionTitle} &rarr;
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}