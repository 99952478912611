'use client'

import { useContext } from "react";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { Link } from "react-router-dom";
import LogoCloud from "./LogoCloud";


export default function Landing() {

  const { setShowWaitlistModal } = useContext(ApplicationContext)

  const displayExampleNotice = () => {
    window.announcer.displayAlert("test_stunning", {
      title: "An Example Popup Title",
      description: "This is an example popup, for when users are about to leave the site. You can customize the design in the dashboard! ",
      callToActionUrl: "/",
      callToActionTitle: "Keep Exploring",
      accentColor: "#10b981",
      textColor: "#111827",
      backgroundColor: "#ffffff"
    })
  }

  return (
    <div className="bg-white">

      <div className="relative isolate px-6 --pt-14 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80 pointer-events-none"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-primary-subtle opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-36 lg:pt-36 lg:pb-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Now 50% off for early adopters!{' '}
              <a href="#" onClick={() => setShowWaitlistModal(true)} className="font-semibold text-primary">
                <span aria-hidden="true" className="absolute inset-0" />
                Get discount <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
              {/* Data to enrich your online business */}
              {/* Engaging Notices. Notably Simple. */}
              Convert Visitors Into <span className="underline decoration-primary decoration-dashed">Customers</span>
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              Automatically design and add <i onClick={displayExampleNotice} className="bg-primary rounded-md text-white px-2 py-1 font-serif hover:cursor-pointer hover:bg-primary-light transition-colors">stunning</i> popups to your site.
              <br/>
              Capture attention, grow your audience, and drive more sales.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                onClick={() => setShowWaitlistModal(true)}
                className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Join Waitlist
              </a>
              <Link to="#features1" className="text-sm/6 font-semibold text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary to-primary-subtle opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  )
}
