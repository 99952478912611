import { createContext, useContext, useEffect, useState } from "react";
import GenericPage from "../../../../components/dashboard/GenericPage";
import { DashboardContext } from "../../../../providers/DashboardProvider";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import BIcon from "../../../../components/BIcon";
import { NewAnnouncementContext } from "../../../../providers/NewAnnouncementProvider";
import AnnouncementPreview from "../../../../components/dashboard/AnnouncementPreview";
import AnnouncementSettings from "../../../../components/dashboard/AnnouncementSettings";
import { tenantNewAnnouncement } from "../../../../Api";

export default function NewAnnouncementPage({ }) {

    // const { id } = useParams()

    const { selectedTenant, refreshTenant } = useContext(DashboardContext)
    const [requiredValues, setRequiredValues] = useState(false)

    const navigate = useNavigate()

    const { state } = useLocation()
    const stateAnnouncementObj = state?.announcementObj

    const [announcementObj, setAnnouncementObj] = useState({
        title: stateAnnouncementObj?.title ?? "Wait a moment! ✋",
        description: stateAnnouncementObj?.description ?? "Before you leave, explore how we can transform your business decisions into growth opportunities.",
        accentColor: "#e16540",
        textColor: "#d1d0cf",
        backgroundColor: "#1f1f1f",
        enabled: true,
        collectEmail: true,
        callToActionTitle: stateAnnouncementObj?.callToActionTitle ?? undefined
    })

    const onChange = (newAnnouncementObj, form) => {
        console.log(newAnnouncementObj)
        setAnnouncementObj(newAnnouncementObj)
        console.log(form.checkValidity())
        setRequiredValues(form.checkValidity())
    }

    const handleSubmit = newAnnouncementObj => {
        tenantNewAnnouncement(selectedTenant.id, newAnnouncementObj).then(res => {
            refreshTenant().then(() => {
                // clear()
                navigate(`/app/announcements/${res.announcement.id}`)
            })
        })
    }

    return (
        <GenericPage full title={"New Notice"}>
            <AnnouncementPreview announcementObj={announcementObj}>
                <AnnouncementSettings announcementObj={announcementObj} onChange={onChange} handleSubmit={handleSubmit} isNew={true} />
                {/* <button form="announcementSettingsForm" type="submit" className="ms-auto text-white rounded-md shadow-sm px-3.5 py-2.5 text-sm bg-primary hover:bg-primary-light">Next &rarr;</button> */}
                <div className="z-10 pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
                    <div className="pointer-events-auto flex items-center justify-between gap-x-2 bg-white border shadow-lg px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                        <p className="text-sm leading-6 pe-2">
                            {/* <strong className="font-semibold">Save Changes</strong>
            <BIcon name={"dot"} /> */}
                            <span>{requiredValues ? "Looks good!" : "Enter the required fields."}</span>
                        </p>
                        {/* <button onClick={() => refreshTenant()} className="text-primary rounded-md shadow-sm px-3.5 py-1.5 text-sm border border-primary hover:border-primary-light">Revert</button> */}
                        {requiredValues && <button type="submit" form="announcementSettingsForm" className="text-white rounded-md shadow-sm px-3.5 py-1.5 text-sm bg-primary hover:bg-primary-light">Next &rarr;</button>}
                    </div>
                </div>
            </AnnouncementPreview>
        </GenericPage>
    )
}