import CTA from "../components/CTA";
import FAQ from "../components/FAQ";
import Features1 from "../components/Features1";
import Features2 from "../components/Features2";
import Features2New from "../components/Features2New";
import Features3 from "../components/Features3";
import Landing from "../components/Landing";
import Pricing from "../components/Pricing";

export default function HomePage({ }) {
  return (
    <div>
      <Landing />
      <Features1/>
      {/* <Features2New/> */}
      <Features3/>
      <Pricing/>
      <FAQ/>
      <CTA/>
    </div>
  )
}