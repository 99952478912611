import BIcon from "./BIcon"

const features = [
  {
    name: '1. Integrate the script',
    description:
      'Add our script to your website, it is essential for the popups to function. Adding one line is all the code you will need to do.',
    href: '#',
    icon: "code",
  },
  {
    name: '2. Press the button',
    description:
      'Instantly add your website to our platform, and then initiate the process by clicking the button.',
    href: '#',
    icon: "clock",
  },
  {
    name: '3. Profit',
    description:
      'Sit back and relax while Notably automatically optimizes your conversion rate. Focus on creating the best product for your users.',
    href: '#',
    icon: "currency-dollar",
  },
]

export default function Features3() {
  return (
    <div className="bg-white py-24 sm:py-32" id="features2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl max-w-xl mx-auto">
            Imagine a <span className="bg-primary text-white px-3 py-1 rounded-xl hover:cursor-pointer hover:bg-primary-light">button</span> that could <span className="text-4xl sm:text-5xl">double</span> your conversions... 🤑
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Using AI, {process.env.REACT_APP_SITE_NAME} intelligently designs and optimizes high-converting popups and lead-generation tools for your website.
            You won't need to code or overthink; it all happens automatically, making your life much easier.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                    <BIcon center name={feature.icon} aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-primary">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
