export default function GenericPage({ title, side, children, flex, full, className, ...props }) {
    return (
        <div className={`h-full flex flex-col p-12 pb-0 ${full ? "" : "max-w-6xl"} ${className}`} {...props}>
            <div className="pb-4 flex flex-row justify-between">
                <div>
                    <h1 className="text-3xl font-bold">{title}</h1>
                </div>
                <div>
                    {side}
                </div>
            </div>
            <div className={`grow overflow-y-auto ${flex ? "flex justify-center items-center" : ""}`}>
                {children}
            </div>
        </div >
    )
}