import { useLoaderData } from "react-router-dom";
import { tenantGetEmailList } from "../../Api";
import EmailList from "../../components/dashboard/EmailList";
import GenericPage from "../../components/dashboard/GenericPage";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../providers/DashboardProvider";
import BIcon from "../../components/BIcon";

export default function ListPage({ }) {

  const { selectedTenant } = useContext(DashboardContext)

  const [emailList, setEmailList] = useState([])

  useEffect(() => {
    if (selectedTenant) {
      tenantGetEmailList(selectedTenant.id).then(res => {
        setEmailList(res.emailList)
      })
    }
  }, [selectedTenant])

  const exportAddresses = () => {
    const csvContent = emailList.map(email => `${email.email},${email.createdAt}`).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'email_list.csv';
    a.click();
    URL.revokeObjectURL(url);

  }

  const sideDiv = (
    <div>
      <button onClick={exportAddresses} className="text-white rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm bg-primary hover:bg-primary-light"><BIcon name={"file-earmark"} className={"me-1"} />Export</button>
    </div>
  )

  return (
    <GenericPage side={sideDiv} title={"List"} description={"A list of all collected email addresses."}>
      <p className="text-gray-600">
        A list of all collected email addresses.
      </p>
      <EmailList emailList={emailList}></EmailList>
    </GenericPage>
  )
}