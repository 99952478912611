import React from "react";
import ReactDOM from 'react-dom/client';

import "bootstrap-icons/font/bootstrap-icons.css";
import './index.css';

import App from './App';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { AuthProvider } from './providers/AuthProvider';
import HomePage from './routes/HomePage';
import { ApplicationProvider } from './providers/ApplicationProvider';
import Dashboard from "./routes/dashboard/Dashboard";
import SignInPage from "./routes/SignInPage";
import { DashboardProvider } from "./providers/DashboardProvider";
import SettingsPage from "./routes/dashboard/SettingsPage";
import AddDomainPage from "./routes/dashboard/AddDomainPage";
import OverviewPage from "./routes/dashboard/OverviewPage";
import AnnouncementsPage from "./routes/dashboard/announcements/AnnouncementsPage";
import NewAnnouncementPage from "./routes/dashboard/announcements/new/NewAnnouncementPage";
import Page0 from "./routes/dashboard/announcements/new/Page0";
import Page1 from "./routes/dashboard/announcements/new/Page1";
import NewAnnouncementProvider from "./providers/NewAnnouncementProvider";
import Page2 from "./routes/dashboard/announcements/new/Page2";
import AnnouncementPage from "./routes/dashboard/announcements/AnnouncementPage";
import OnboardingProvider from "./providers/OnboardingProvider";
import OnboardingPage from "./routes/dashboard/onboarding/OnboardingPage";
import RequireOnboarded from "./components/RequireOnboarded";
import PrivacyPolicyPage from "./routes/legal/PrivacyPolicyPage";
import TermsAndConditionsPage from "./routes/legal/TermsAndConditionsPage";
import { AnalyticsProvider } from "./providers/AnalyticsProvider";
import NewAnnouncementMagicPage from "./routes/dashboard/announcements/new/NewAnnouncementMagicPage";
import ListPage from "./routes/dashboard/ListPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AnalyticsProvider />}>
      <Route element={<AuthProvider />}>
        <Route element={<ApplicationProvider />}>
          <Route element={<DashboardProvider />}>
            <Route element={<OnboardingProvider />}>
              <Route path="/onboarding" element={<OnboardingPage />} />
            </Route>
            <Route element={<RequireOnboarded />}>
              <Route path="/app" element={<Dashboard />}>
                <Route index element={<Navigate to="/app/overview" replace />} />
                <Route path="overview" element={<OverviewPage />} />
                <Route path="announcements">
                  <Route index element={<AnnouncementsPage />} />
                  <Route path=":id" element={<AnnouncementPage />} />
                  <Route path="new">
                    <Route index element={<Navigate to={"magic"} replace />} />
                    <Route path="magic" element={<NewAnnouncementMagicPage />} />
                    <Route path="design" element={<NewAnnouncementPage />} />
                  </Route>
                </Route>
                <Route path="list" element={<ListPage />}/>
                <Route path="settings" element={<SettingsPage />} />
                <Route path="add-domain" element={<AddDomainPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="/" element={<App />}>
            <Route index element={<HomePage />} />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/legal/terms-and-conditions" element={<TermsAndConditionsPage />} />
          </Route>
          <Route path="/signin" element={<SignInPage />} />
        </Route>
        {/* <Route path="reset-password" element={<PasswordResetRequestPage />} />
      <Route path="change-password" element={<ChangePasswordPage />} /> */}
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <RouterProvider router={router} />
  </>
);